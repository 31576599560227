<template>
  <div>
    <div
      class="
        uk-section uk-padding-remove-vertical uk-margin-remove-vertical
        in-equity-breadcrumb
      "
    >
      <div class="uk-container">
        <div class="uk-grid">
          <div class="uk-width-1-1">
            <ul class="uk-breadcrumb">
              <li>
                <router-link :to="{ name: 'trader.home' }">Home</router-link>
              </li>
              <li><span>Academy</span></li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <div class="uk-container" v-if="details.week == null">
      <div
        class="
          uk-grid
          uk-child-width-expand
          uk-margin-medium-top
          uk-background-default
          uk-padding
        "
      >
        <div class="uk-alert-primary" uk-alert>
          <p>
            There are is no more content at this time. Please try again later.
          </p>
        </div>
      </div>
    </div>

    <div class="uk-container" v-if="details.week != null">
      <div
        class="
          uk-grid
          uk-child-width-expand
          uk-margin-medium-top
          uk-background-default
          uk-padding
        "
        v-if="! loading"
      >
        <div>
          <video-thumbnail :video="details.week" />
        </div>
        <div>
          <p class="uk-margin-remove uk-text-meta">
            Section {{ details.section.sort }}: {{ details.section.title }}.
          </p>
          <p class="uk-margin-small uk-text-meta">
            Course {{ details.course.sort }}: {{ details.course.title }}
          </p>
          <h3 class="uk-margin-remove">
            Week {{ details.week.sort }}: {{ details.week.title }}
          </h3>
          <p class="uk-margin-small" v-html="details.week.about"></p>
          <h2 class="uk-margin-small" v-if="! details.week.paid">
            {{ details.week.cost > 0 ? `$ ${details.week.cost}` : "Free" }}
          </h2>
          <router-link
            :to="{ name: 'trader.academy.pay', params: { id: details.week.id } }"
            class="uk-button uk-button-primary"
            >{{ details.week.paid ? 'Continue' : 'Start'}}</router-link
          >
        </div>
      </div>
      <div
        class="
          uk-grid
          uk-child-width-expand
          uk-margin-medium-top
          uk-background-default
          uk-padding-large
          text-center
        "
        v-if="loading"
      >
        <loading />
      </div>
    </div>

    <div class="uk-section" v-if="completed_weeks.length > 0">
      <div class="uk-container">
        <h4>Completed Weeks {{ completed_weeks.length }} / {{ total_weeks }}</h4>
        <div class="uk-grid" data-uk-grid="">
          <div
            class="uk-width-1-5@m uk-first-column"
            v-for="(week, i) in completed_weeks"
            :key="`completed-week-${i}`"
          >
            <div class="uk-card uk-card-default">
              <div class="uk-card-media-top">
                <router-link
                  :to="{ name: 'trader.academy.load', params: { id: week.id } }"
                >
                  <video-thumbnail :video="week" />
                </router-link>
              </div>
              <div class="uk-padding-small">
                <router-link
                  :to="{ name: 'trader.academy.load', params: { id: week.id } }"
                  ><h5>{{ week.title }}</h5></router-link
                >
                <router-link :to="{ name: 'trader.academy.load', params: { id: week.id }}" class="uk-button uk-button-primary" style="width: 100%;margin-top: 10px;">Re-watch</router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    this.fetch();
  },

  data() {
    return {
      details: { section: {}, course: {}, week: {} },
      loading: true,
      weeks: 0,
      completed_weeks: [],
      total_weeks: ''
    };
  },

  methods: {
    fetch() {
      this.loading = true;
      this.$axios.get("/api/v1/trader/academy").then((response) => {
        this.details = response.data.details;
        this.completed_weeks = response.data.completed_weeks;
        this.total_weeks = response.data.total_weeks;
        this.loading = false;

        document.getElementById('completed-weeks-header').innerHTML = `Completed ${this.completed_weeks.length} / ${this.total_weeks} weeks`
      });
    },
  },
};
</script>